<template>
  <div class="h-100">
    <card-text-atlas
      :title="title"
      :is-clickable="true"
      :display-footer="true"
      :display-label="showLabel"
      :label-text="label"
      cta-link="javascript:void(0)"
      cta-text="View course details"
      @cardClick="showModal"
      @cardEnter="showModal"
      @ctaClick.prevent
      class="h-100"
    ></card-text-atlas>
    <rds-modal
      @hidden="modalVisible = false"
      :visible="modalVisible"
      use-custom-close
      size="lg"
    >
      <div class="d-block">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            aria-label="Close"
            class="modal-close me-space-xs mt-space-xxs"
            @click="hideModal"
          >
            ×
          </button>
        </div>
        <div
          class="px-space-md px-md-space-lg pt-space-lg pb-space-md pb-lg-space-lg"
        >
          <div class="mb-space-xxs">
            <span
              class="pt-space-xxxs pb-space-xxxs fs-small text-white fw-bold px-space-xxs bg-dark-3"
              >{{ label }}</span
            >
          </div>
          <h3 class="h3-large mb-space-sm">{{ title }}</h3>
          <div class="fs-sm mb-space-sm" v-html="description"></div>

          <div class="ratio ratio-16x9">
            <iframe
              title="Video"
              class="w-100 h-100"
              :src="videoEmbedUrl"
            ></iframe>
          </div>
        </div>
      </div>
    </rds-modal>
  </div>
</template>
<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { CardTextAtlas } from "@rds-vue-ui/card-text-atlas";
import { ref } from "vue";
interface Props {
  title: string;
  description: string;
  showLabel?: boolean;
  label?: string;
  eventComponent: string;
  videoId: string;
}
const props = withDefaults(defineProps<Props>(), {
  showLabel: false,
});
const modalVisible = ref(false);
const showModal = () => {
  modalVisible.value = true;
  analyticsComposable.trackModalEvent(
    "onclick",
    "open",
    "click",
    "main content",
    props.title.toLowerCase(),
    "view details",
    props.eventComponent.toLowerCase()
  );
};
const hideModal = () => {
  modalVisible.value = false;
  analyticsComposable.trackModalEvent(
    "onclick",
    "open",
    "click",
    "close cross",
    props.title.toLowerCase(),
    "view details",
    props.eventComponent.toLowerCase()
  );
};
const videoEmbedUrl = computed(() => {
  return `https://www.youtube.com/embed/${props.videoId}`;
});
</script>

<style lang="scss" scoped></style>
