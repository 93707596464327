<template>
  <div class="outside-border" :style="outerBorderStyle">
    <p
      class="fs-large fw-bold py-space-xxs my-space-xxs ps-space-xxs mx-space-xxs"
      :style="innerBorderStyle"
    >
      {{ text }}
    </p>
  </div>
</template>

<script setup lang="ts">
interface Props {
  text: string;
  innerBorderColor?: string;
  outerBorderColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
  innerBorderColor: "var(--rds-secondary)",
  outerBorderColor: "var(--rds-light-2)",
});

const innerBorderStyle = computed(() => {
  return "border-left: 2px solid " + props.innerBorderColor;
});

const outerBorderStyle = computed(() => {
  return "border: 2px solid " + props.outerBorderColor;
});
</script>

<style lang="scss" scoped>
.outside-border {
  border-radius: 4px;
}
</style>
