<template>
  <section
    class="px-space-xs px-md-space-md px-lg-space-xl py-space-md py-md-space-lg py-lg-space-xxl section-bg rds-lazy"
    data-background-image="https://live-asuocms.ws.asu.edu/sites/default/files/Topo%20background%402x.jpg"
  >
    <div class="container px-0">
      <!-- title & description -->
      <div class="px-space-xs px-md-0">
        <div class="row gx-0">
          <div class="col">
            <h2 class="mb-space-sm mb-md-space-md mb-lg-space-sm h2-medium">
              {{ title }}
            </h2>
            <div class="mb-space-md mb-md-space-sm mb-lg-space-md">
              <div v-html="description"></div>
            </div>
          </div>
        </div>
        <!-- horizontal line -->
        <div class="row">
          <div class="col">
            <hr
              class="text-dark-3 hr-underline mb-space-md mb-md-space-sm mb-lg-space-md mt-0"
            />
          </div>
        </div>
      </div>
      <!-- course cards -->
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 mb-space-xs">
        <div
          class="col mb-space-xs mb-lg-space-sm"
          v-for="(course, index) in currentCourses"
          :key="index"
        >
          <card-modal-course
            v-if="!course.youtubeVideoId"
            :title="course.title"
            :description="getDescription(course.description)"
            :show-label="getLabelStatus(course)"
            :label="course.category"
            :event-component="title"
          ></card-modal-course>

          <card-modal-video
            v-else
            :title="course.title"
            :description="getDescription(course.description)"
            :show-label="getLabelStatus(course)"
            :label="course.category"
            :video-id="course.youtubeVideoId"
            event-component="course trailer"
          ></card-modal-video>
        </div>
      </div>

      <!-- show more/less links -->
      <div v-if="displayShowAllLess" class="row">
        <div class="col">
          <div class="text-center">
            <button
              @click="handleShowAllLessClick"
              class="btn btn-link fs-medium text-primary show-text text-decoration-underline"
            >
              {{ getShowAllLessText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { computed, ref } from "vue";
import CardModalCourse from "~/components/degree-page/CardModalCourse.vue";
import CardModalVideo from "~/components/degree-page/CardModalVideo.vue";

interface Courses {
  category: string;
  description: string;
  title: string;
  youtubeVideoDuration: string | null;
  youtubeVideoId: string | null;
  youtubeVideoUploadDate: string | null;
}

interface Props {
  title: string;
  description: string | null;
  courses: Courses[];
  initialItemsNumber?: number;
}
const props = withDefaults(defineProps<Props>(), {
  initialItemsNumber: 8,
});

const allCoursesShown = ref(false);
const currentCourses = ref<Courses[]>([]);

const displayShowAllLess = computed(() => {
  return props.courses.length > props.initialItemsNumber;
});
const getShowAllLessText = computed(() => {
  return allCoursesShown.value ? "Show less" : "Show more";
});

const handleShowAllLessClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    getShowAllLessText.value.toLowerCase(),
    "featured courses"
  );
  allCoursesShown.value = !allCoursesShown.value;
  updateCurrentCourses();
};

const getDescription = (courseDescription: string | null) => {
  return courseDescription
    ? courseDescription
    : "No description available at this time.";
};

const getLabelStatus = (course: Courses) => {
  return course.category !== null && course.category !== "";
};

const updateCurrentCourses = () => {
  if (allCoursesShown.value) {
    currentCourses.value = props.courses;
  } else {
    currentCourses.value = props.courses.slice(0, props.initialItemsNumber);
  }
};

onMounted(() => {
  currentCourses.value = props.courses.slice(0, props.initialItemsNumber);
});
</script>

<style scoped>
.hr-underline {
  height: 0.25px;
}
.section-bg {
  background-size: auto;
  background-position: top;
}
</style>
