<template>
  <section
    class="bg-white pt-0 pb-space-md pt-md-space-lg py-lg-space-xxl px-lg-space-xxxl"
  >
    <div class="container-lg position-relative">
      <!-- image -->
      <div class="row">
        <div
          class="col career-image rds-lazy"
          :data-background-image="imageSource"
        ></div>
        <div class="w-100 career-image-gradient"></div>
      </div>
      <!-- title row -->
      <div class="row position-relative">
        <div class="col-12 offset-lg-1 col-lg-10">
          <h2
            class="h2-medium title-margin pt-space-md pt-md-space-lg pt-lg-0 pb-space-sm pb-md-space-md pb-lg-space-sm mb-0 px-md-space-xs px-lg-0 relative"
          >
            {{ title }}
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-10">
          <div
            class="fs-medium px-0 px-md-space-xs px-lg-0 pb-space-md pb-md-space-sm pb-lg-space-md mb-0"
            v-html="description"
          ></div>
        </div>
      </div>

      <!-- career cards -->
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-10">
          <div class="px-0 px-md-space-xs px-lg-0">
            <div class="row">
              <div
                class="col-12 col-md-6 col-lg-4 card-deck mx-0 px-space-xs"
                v-for="(career, index) in componentData.currentCareerItems"
                :key="index"
              >
                <CardRelatedCareer
                  :text="career.title!"
                  class="mb-space-xs w-100"
                ></CardRelatedCareer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- show more/less links -->
      <div v-if="displayShowAllLess" class="row">
        <div class="col">
          <div class="text-center">
            <button
              @click="handleShowAllLessClick"
              class="btn btn-link fs-medium text-primary show-text text-decoration-underline"
            >
              {{ getShowAllLessText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ParagraphRelatedCareersExamples } from "@/server/resolvers/resolvers-types";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import CardRelatedCareer from "./CardRelatedCareer.vue";

const props = withDefaults(
  defineProps<{
    title: string;
    imageSource: string;
    description: string | null;
    careers: ParagraphRelatedCareersExamples[];
    initialItemsNumber?: number;
  }>(),
  {
    initialItemsNumber: 9,
  }
);

const componentData = reactive({
  showAllItems: false,
  currentCareerItems: [] as ParagraphRelatedCareersExamples[],
});

// computed properties
const getShowAllLessText = computed(() => {
  return componentData.showAllItems ? "Show less" : "Show more";
});
const displayShowAllLess = computed(() => {
  return props.careers.length > props.initialItemsNumber;
});
// methods
const handleShowAllLessClick = (event: Event) => {
  event.preventDefault();
  const { title, careers, initialItemsNumber } = props;

  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    title.toLowerCase(),
    getShowAllLessText.value.toLowerCase(),
    "related careers"
  );

  componentData.showAllItems = !componentData.showAllItems;

  componentData.currentCareerItems = componentData.showAllItems
    ? careers
    : careers.slice(0, initialItemsNumber);
};

onMounted(() => {
  componentData.currentCareerItems = props.careers.slice(
    0,
    props.initialItemsNumber
  );
});
</script>

<style scoped lang="scss">
.career-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 245px;
  padding-left: 0;
  padding-right: 0;
}
.career-image-gradient {
  left: 0px;
  height: 245px;
  position: absolute;
  background: transparent
    linear-gradient(180deg, #ffffff00 0%, #ffffffa0 80%, #ffffff 100%) 0% 0%
    no-repeat;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .career-image {
    height: 400px;
  }
  .career-image-gradient {
    height: 400px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .career-image {
    height: 550px;
  }
  .career-image-gradient {
    height: 550px;
  }
  .title-margin {
    margin-top: -40px;
  }
}
</style>
